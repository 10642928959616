var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"320px"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"User ID","mode":"eager","rules":"required|alpha_dash|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"vpn_key","label":"User ID","type":"text","autocomplete":"username","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Display name","mode":"eager","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"person","label":"Display name","type":"text","autocomplete":"name","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.hasEmailParam,"prepend-inner-icon":"email","label":"Email","type":"email","autocomplete":"email","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","mode":"eager","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"lock","label":"Password","id":"password","type":"password","autocomplete":"new-password","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password confirmation","mode":"eager","rules":"required|confirms:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"lock","label":"Confirm password","id":"passwordConfirm","type":"password","autocomplete":"new-password","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"block":"","color":"primary","type":"input","disabled":invalid || !_vm.hasTokenParam},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v("Create account")])],1),(_vm.errorMsg)?_c('div',{staticClass:"text-body-2 error--text mt-2"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('div',{staticClass:"my-2 text-center"},[_c('span',{staticClass:"caption"},[_c('router-link',{attrs:{"to":"/auth/login"}},[_vm._v("Already have an account?")])],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }