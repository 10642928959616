

































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileCreate } from '@/interfaces';
import { dispatchSignupUser } from '@/store/main/actions';
import { commitAddNotification } from '@/store/main/mutations';

@Component
export default class Login extends Vue {
  $refs!: {
    observer
  }

  public userId: string = '';
  public displayName: string = '';
  public email: string = '';
  public password: string = '';
  public passwordConfirm: string = '';

  private errorMsg: string = '';
  private hasTokenParam: boolean = false;
  private hasEmailParam: boolean = false;

  public mounted() {
    this.checkToken();
    if (this.$router.currentRoute.query.to) {
      this.email = (this.$router.currentRoute.query.to as string);
      this.hasEmailParam = true;
    }
  }

  public checkToken() {
    const token = (this.$router.currentRoute.query.token as string);
    if (!token) {
      this.errorMsg = "Disabled because no token is provided in the URL"
      this.hasTokenParam = false;
    } else {
      this.errorMsg = ""
      this.hasTokenParam = true;
      return token;
    }
  }

  public async submit() {
    this.$refs.observer.validate().then(async () => {
      const token = this.checkToken();
      if (token) {
        const profileCreate: IUserProfileCreate = {
          email: this.email,
          user_id: this.userId,
          password: this.password
        };
        if (this.displayName) {
          profileCreate.display_name = this.displayName;
        }
        try {
          await dispatchSignupUser(this.$store, {userData: profileCreate, token});
          this.$router.push('/');
        } catch (e) {
          this.errorMsg = e.message;
        }
      } else {
        commitAddNotification(this.$store, {
          content: 'No token provided in the URL',
          color: 'error',
        });
      }
    });
  }
}
