


















import { Component, Vue } from 'vue-property-decorator';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';
import { commitAddNotification } from '@/store/main/mutations';

@Component
export default class Login extends Vue {
  public login: string = '';
  public password: string = '';

  public get loginError() {
    return readLoginError(this.$store);
  }

  public submit() {
    if (this.login && this.password) {
      dispatchLogIn(this.$store, {username: this.login, password: this.password});
    } else {
        commitAddNotification(this.$store, {
          content: 'Please enter credentials',
          color: 'error',
        });
      }
  }
}
